@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: local('PlayfairDisplay-Bold'),
    url('./fonts/PlayfairDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-Regular';
  src: local('PlayfairDisplay-Regular'),
    url('./fonts/PlayfairDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'PlayfairDisplay-SemiBold';
  src: local('PlayfairDisplay-SemiBold'),
    url('./fonts/PlayfairDisplay-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: local('Raleway-SemiBold'),
    url('./fonts/Raleway-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local('Raleway-Regular'),
    url('./fonts/Raleway-Regular.ttf') format('truetype');
}

html{
  background-color: #021952;
}

/*header*/
.header {
  background-color: #021952;
  color: white;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  padding: 1.0416vw; /* 20px */
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 3.125vw; /* 60px */
}

.logo span {
  font-size: 1.5625vw; /* 30px */
  font-weight: bold;
  margin-left: 0.5208vw; /* 10px */
}

.nav ul {
  display: flex;
  list-style-type: none;
  padding-top: 1vw;
  margin: 0;
}

.nav li {
  margin: 0 1.0416vw; /* 20px */
  font-size: 1.0416vw; /* 20px */
  cursor: pointer;
}

.nav li a {
  text-decoration: none;
  color: white;
}

.nav li a:hover {
  text-decoration: underline;
}

.search-lang {
  display: flex;
  align-items: center;
}

.search-lang input {
  padding: 0.5208vw; /* 10px */
  font-size: 1.0416vw; /* 20px */
  margin-right: 1.0416vw; /* 20px */
  border: none;
  border-radius: 0.5208vw; /* 10px */
}

.lang img {
  height: 1.0416vw; /* 20px */
  margin-left: 0.5208vw; /* 10px */
}

/*main-content*/

.main-content {
  text-align: center;
  color: white;
}

.welcome-section {
  background-image: url(./assets/images/bannerone.svg);
  background-size: cover;
  background-position: center;
  height: 22.396vw;
  background-position: 100% 97%;
}

.welcome-section h1 {
  font-family: 'PlayfairDisplay-Bold';
  font-size: 3vw; /* 48px */
  margin: 0;
  padding: 6.25vw 0; 
}

.about-section {
  background-color: #010E30;
  padding: 5.208vw 0; /* 100px */
  color: #d4af37; /* золотой цвет */
}

.about-section h2 {
  font-family: 'Raleway-Regular';
  font-size: 2.0833vw; /* 40px */
  margin-bottom: 2.0833vw; /* 40px */
  background-color: #02123E;
  padding: 2vw 0;
  color: #fff;
}

.about-section h3 {
  font-family: 'Raleway-SemiBold';
  font-size: 3vw; /* 30px */
  margin: 2.0833vw 0; /* 40px */
}

.about-section p {
  font-family: 'Raleway-Regular';
  font-size: 2.5vw; /* 20px */
  margin: 1.0416vw 0; /* 20px */
  color: white;
}

/*footer*/
.footer {
  background-color: #021952;
  color: white;
  font-family: 'Raleway-Regular';
  display: flex;
  justify-content: center;
  padding: 2.0833vw; /* 40px */
}

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo img {
  height: 3.125vw; /* 60px */
  margin-bottom: 1.0416vw; /* 20px */
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.footer-logo p {
  max-width: 21.75vw; /* 360px */
  line-height: 1.5;
  font-size: 0.7vw; 
}

.footer-span-color{
  color: #0485B5;
}

.footer-contact,
.footer-nav {
  display: flex;
  font-family: 'Raleway-Regular';
  flex-direction: column;
}

.footer-contact h4,
.footer-nav h4 {
  font-family: 'Raleway-Regular';
  margin-bottom: 1.6vw; /* 20px */
  font-size: 1.25vw; /* 24px */
  font-weight: 400;
  text-align: center;
}

.footer-contact ul,
.footer-nav ul {
  font-family: 'Raleway-Regular';
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-contact li,
.footer-nav li {
  display: flex;
  font-family: 'Raleway-Regular';
  align-items: center;
  margin-bottom: 0.5208vw; /* 10px */
  font-size: 1.0416vw; /* 20px */
}

.footer-contact li img,
.footer-nav li img {
  font-family: 'Raleway-Regular';
  width: 1.5vw;
  padding-right: 0.5vw;
}

.footer-contact li a,
.footer-nav li a {
  font-family: 'Raleway-Regular';
  text-decoration: none;
  color: #fff;
  text-align: center;
}

.footer-contact li img:last-child {
  font-family: 'Raleway-Regular';
  margin-right: 0;
}


/*contact-form*/

.contact-form-container {
  background-color: #010E30;
  color: white;
  text-align: center;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.contact-form-container h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: 'PlayfairDisplay-Bold';
}

.contact-form-container p {
  color: #00a3ff;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 400;
  font-family: 'Raleway-Regular';
}

.contact-form {
  background-color: #021952;
  padding: 2rem;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
  display: inline-block;
  width: 100%;
  max-width: 25%;
}

.contact-form-container h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-family: 'Raleway-Regular';
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form input[type="text"],
.contact-form input[type="tel"] {
  width: 70%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1rem;
}

.contact-form label {
  color: white;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.contact-form input[type="checkbox"] {
  margin-right: 0.5rem;
}

.contact-form button {
  background-color: #d4af37;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.7rem 1.5rem;
  margin-top: 0.5vw;
  font-size: 1rem;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #c19a2f;
}

/*services*/

.services-container {
  background-color: #010E30;
  color: white;
  text-align: center;
  font-family: Arial, sans-serif;
}

.services-container h1 {
  font-family: 'PlayfairDisplay-Bold';
  font-size: 4vw; /* 48px */
  margin: 0;
  padding: 7.25vw 0; 
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  justify-items: center;
  padding: 7vw 0;
}

.service-card {
  background-color: #021952;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  text-align: center;
}

.service-card:hover {
  transform: translateY(-0.5rem);
}

.service-icon {
  margin-bottom: 1rem;
}

.service-icon img{
  width: 3vw;
}

.services-banner{
  width: 100%;
  background-image: url(./assets/images/bannertwo.svg);
  background-size: cover;
  background-position: center;
  height: 22.396vw;
  background-position: 100% 97%;
}

.service-card p {
  font-size: 1.2vw;
  line-height: 1.5;
  color: #C09E4A;
  font-family: 'Raleway-Regular';
}

.wrapper-services{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

/*features*/
.features{
  background-color: #010E30;
}

.features-wrapper {
  width: 80vw;
  padding: 2.5vw;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}


.features-title-conteiner{
  width: 100%;
  background-image: url(./assets/images/banneronethree.svg);
  background-size: cover;
  background-position: center;
  height: 27.396vw;
  background-position: 100% 97%;
}

.features-title {
  color: #ffffff;
  text-align: center;
  font-size: 3.5vw;
  margin: 0;
  padding-top: 10vw;
  font-family: 'PlayfairDisplay-Bold';
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 2vw;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 3vw;
  font-size: 2.8vw;
  color: #ffffff;
  font-family: 'Raleway-Regular';
}

.feature-icon img{
  width: 3vw;
  margin-right: 1vw;
}

.feature-text {
  flex: 1;
}

.feature-text a {
  color: #00aaff;
}

/**/

.mainselection{
  padding: 4vw 0;
  background-color: #010E30;
}

.main-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw 0;
  box-sizing: border-box;
  background-color: #010E30;
  flex-direction: column;
}

.main-section {
  width: 80%;
  background-color: #010E30;
  color: #fff;
}

.main-title {
  text-align: center;
  font-size: 4.2vw;
  margin: 0vw;
  border: none;
  padding: 3vw 0;
  font-family: 'Raleway-Regular';
  background-color: #021952;
  color: #C09E4A;
}

.steps {
  margin-bottom: 3vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6vw;
}

.steps img{
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2vw;
}

.step-number {
  font-size: 4vw;
  color: #00bfff;
  margin-right: 1vw;
}

.step-text h2 {
  font-size: 1.5vw;
  color: #00bfff;
  margin: 0;
}

.step-text p {
  font-size: 1.2vw;
}

.integrator {
  text-align: center;
  margin-bottom: 3vw;
  margin-top: 4vw;
}

.integrator img {
  width: 40vw;
  margin-right: 0.5vw;
}



.button {
  display: block;
  margin: 0 auto;
  padding: 0.5vw 1.5vw;
  background-color: #ffd700;
  color: #001a4b;
  border: none;
  border-radius: 2vw;
  font-size: 1.5vw;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  background-color: #e6be00;
}

.feedback-set{
  display: none;
  visibility: hidden;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-color: #010E30;
 }

 .feedback-set h2{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 56vw;
  font-family: 'Raleway-Regular';
  font-size: 3.5vw;
  line-height: 1;
  font-weight: 400;
  color: #d4af37;
  padding-bottom: 0.8vw;
  padding-top: 1vw;
 }

 .feedback-set h3{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 56vw;
  font-family: 'Raleway-Regular';
  font-size: 2vw;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  padding-top: 0.5vw;
 }

 .feedback-set h4{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 56vw;
  font-family: 'Raleway-Regular';
  font-size: 0.9vw;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  padding-top: 0.5vw;
 }

 .feedback-set a{
  color: #d4af37;
 }

 .feedback-set img{
  width: 15vw;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 3vw;
 }

 .styles-module_whatsappButton__tVits{
  background-color: #d4af37 !important;
}

.styles-module_whatsappButton__tVits:after{
  box-shadow: 0 0 0 0 rgba(209, 188, 125, 0) !important ;
}

.styles-module_chatHeader__gaeOA{
  background-color: #d4af37 !important;
}

.chatbox-WhatsApp p{
  font-family: 'Arimo', 'Nunito', sans-serif;
  padding: 0;
  margin: 0;
  font-style:normal;
}



@media screen and (max-width:767px){
  .contact-form {
    background-color: #021952;
    padding: 2rem;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    display: inline-block;
    width: 100%;
    max-width: 75%;
  }

  .service-card{
    width: 70%;
  }

  .service-icon img{
    width: 12vw;
  }

  .service-card p{
    font-size: 6vw;
  }

  .welcome-section{
    height: 50vw;
  }

  .welcome-section h1{
    font-size: 7.5vw;
    padding: 7.5vw 0;
  }

  .about-section h2{
    font-size: 6vw;
  }

  .about-section h3{
    font-size: 6vw;
    padding: 5vw 0;
  }

  .about-section p{
    font-size: 5vw;
    padding: 5vw 0;
  }

  .logo img{
    height: 12vw;
  }

  .nav li{
    font-size: 3vw;
    margin: 3vw 0.7vw ;
  }

  .footer-logo img{
    height: 6vw;
  }

  .footer-logo p{
    font-size: 1.5vw;
    max-width: 27.75vw;
  }

  .footer-contact h4, .footer-nav h4{
    font-size: 2vw;
    margin-bottom: 4vw;
  }

  .footer-contact li, .footer-nav li{
    font-size: 2vw;
    margin-bottom: 2vw;
  }

  .features-title{
    font-size: 7.5vw;
    padding: 10.5vw 0;
  }

  .features-title-conteiner{
    height: 50vw;
    
  }

  .integrator img{
    width: 70vw;
  }

  .button{
    font-size: 3.5vw;
  }

  .steps img{
    width: 100%;
  }

  .main-title{
    font-size: 5vw;
  }

  .feature-item{
    font-size: 4.5vw;
  }

  .feature-icon img{
    width: 5vw;
    margin-right: 3vw;
  }

  .services-banner{
    height: 50vw;
    
  }

  .services-container h1{
    font-size: 7.5vw;
    padding: 16.5vw 0;
  }

  .contact-form-container h1{
    font-size: 7.5vw;
  }

  .contact-form-container p{
    font-size: 6.5vw;
  }
  
}